import { ArrowLeftOutlined } from '@ant-design/icons';
import { message, Spin, DatePicker, Row, Col } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import InventoryItemWidget from 'pages/InventoryPage/InventoryItemWidget/InventoryItemWidget';
import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import { apiDeleteInventoryName, apiGetInventoryName, apiUpdateInventoryName } from 'services/InventoryGroupService';
import { apiAppendInventory, apiCreateInventory } from 'services/inventoryService';
import { apiGetSalableType } from 'services/salableTypeService';
import { showAddInventoryDialog } from './AddInventoryDialog/showAddInventoryDialog';
import { showAppendInventoryInfoDialog } from './AppendnventoryInfoDialog/showAppendInventoryInfoDialog';
import { showEditInventoryInfoDialog } from './EditInventoryInfoDialog/showEditInventoryInfoDialog';
import "./InventoryGroupByName.css"
import TableWidget from './TableWidget/TableWidget';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
const { RangePicker } = DatePicker;

function InventoryGroupByName() {

    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [salableTypeList, setSalableTypeList] = useState([]);
    const [showInventoryList, setShowInventoryList] = useState(true);
    const [showItems, setShowItems] = useState(false);
    const [historyItemList, setHistoryItemList] = useState({ subItemList: [] });

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchText, setSearchText] = useState("");
    const [needReresh, setNeedRefresh] = useState("");

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(current);
    };
    const onChange: PaginationProps['onChange'] = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setIsBusy(true);
        apiGetInventoryName(currentPage, pageSize, startDate, endDate, searchText)
            .then((data) => {
                setIsBusy(false)
                if (data.nameList === undefined) {
                    setIsBusy(false);
                    setDataList([]);
                    setSearchList([]);
                } else {
                    setIsBusy(false);
                    setDataList(data.nameList);
                    setSearchList(data.nameList);
                }
                setTotalCount(data.total_count)
            })
            .catch((err) => {
                setIsBusy(false)
                message.error("Sorry! " + err);
            });
    }, [pageSize, currentPage, startDate, endDate, searchText, needReresh]);

    useEffect(() => {
        apiGetSalableType()
            .then((data) => {
                setSalableTypeList(data);
            })
            .catch((err) => {
                setSalableTypeList([])
                // message.error("Server failed. " + err);
            });
    }, []);


    const handleSearch = (text) => {
        setCurrentPage(1)
        setSearchText(text.toLowerCase())
    };
    const handleChange = (event) => {
        setCurrentPage(1)
        if (event.target.value === "") {
            setSearchText("")
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onClickDelete = (pInfo) => {
        setIsBusy(true);
        apiDeleteInventoryName(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                let updateData = [...dataList]
                var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
                updateData.splice(storeIndex, 1);
                var tmpData = [...updateData];
                setDataList(tmpData);
                setSearchList(tmpData);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }
    const onAppendAction = async (pInfo) => {
        let info = pInfo
        var res = await showAppendInventoryInfoDialog({
            title: "Append QTY to \"" + pInfo.name + "\"",
        });
        if (res === null) return;
        res["id"] = pInfo["id"]
        setIsBusy(true);
        apiAppendInventory(res)
            .then((data) => {
                setIsBusy(false);
                setNeedRefresh(Date.now())
                message.success("Successed")
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err);
            });
    }
    const onUpdateAction = async (pInfo) => {
        let info = pInfo
        var res = await showEditInventoryInfoDialog({
            title: "Edit Inventory Info",
            selInfo: info,
            isEditing: true,
            salableTypeList: salableTypeList
        });
        if (res === null) return;
        setIsBusy(true);
        apiUpdateInventoryName(res)
            .then((data) => {
                setIsBusy(false);
                let updateData = [...dataList]
                var storeIndex = updateData.findIndex(x => x.id == res.id);
                updateData.splice(storeIndex, 1, res);
                var tmpData = [...updateData];
                setDataList(tmpData);
                setSearchList(tmpData);
                message.success("Successed")
            })
            .catch((err) => {
                setIsBusy(false);
                //message.error("Server error. " + err);
                setDataList([]);
                setSearchList([]);
            });

    }

    const onHistoryAction = async (pInfo) => {
        setShowItems(true)
        setHistoryItemList(pInfo)
    }

    const handleDateFilter = (startDate, endDate) => {
        setCurrentPage(1)
        setStartDate(startDate)
        setEndDate(endDate)
    }
    const addNewInventory = async () => {
        var res = await showAddInventoryDialog({
            title: "Create New Inventory",
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true);
        apiCreateInventory(res)
            .then((data) => {
                setIsBusy(false);
                setNeedRefresh(Date.now())
                message.success("Successed")
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err);
            });

    }

    const itemUI = historyItemList?.subItemList.length == 0 ? <div>You don't have any inventory list</div> : historyItemList.subItemList.map((info, index) => {
        return (
            <Col xs={24} sm={8} md={8} lg={8} xl={4} key={index}>
                <InventoryItemWidget
                    inventoryInfo={info}
                    parentInfo={historyItemList}
                />
            </Col>
        );
    });

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <div>
                    {showInventoryList && (
                        <div>
                            <Row>
                                <Col>
                                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                                        <RangePicker size={'default'}
                                            onChange={(e, date) => {
                                                if (date == null) {
                                                    handleDateFilter("", "")
                                                } else {
                                                    handleDateFilter(date[0], date[1])
                                                }

                                            }} />
                                    </div>
                                </Col>
                                <Col flex={'auto'}>
                                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                                        <TitleSearch
                                            onSearch={handleSearch}
                                            onChange={handleChange}
                                            onPressEnter={handleSearch}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                                        <CommonButton onClick={() => {
                                            addNewInventory()
                                        }}>New Inventory</CommonButton>
                                    </div>
                                </Col>
                            </Row>
                            <Row justify={"center"}>
                                <Col>
                                    <Pagination
                                        showSizeChanger
                                        onShowSizeChange={onShowSizeChange}
                                        onChange={onChange}
                                        defaultCurrent={1}
                                        current={currentPage}
                                        total={totalCount}
                                        pageSize={pageSize}
                                    />
                                </Col>
                            </Row>
                            <div>
                                <TableWidget
                                    dataList={searchList}
                                    setDataList={setSearchList}
                                    onUpdateAction={onUpdateAction}
                                    onDeleteAction={onClickDelete}
                                    onHistoryAction={onHistoryAction}
                                    onAppendAction={onAppendAction}
                                    salableTypeList={salableTypeList}
                                />
                            </div>
                        </div>
                    )}

                    <CSSTransition
                        in={showItems}
                        timeout={300}
                        unmountOnExit
                        onEnter={() => setShowInventoryList(false)}
                        onExited={() => setShowInventoryList(true)}
                    >
                        <div>
                            <div style={{
                                padding: "0px 0px 12px 0px",
                            }}>
                                <ArrowLeftOutlined
                                    className="backward-button"
                                    onClick={() => setShowItems(false)}
                                />
                                <span style={{ fontSize: 16 }}>&nbsp;&nbsp;Go back to Inventory List</span>
                            </div>
                            <Row gutter={16} style={{ marginTop: "10px", textAlign: 'center' }}>
                                {itemUI}
                            </Row>

                        </div>

                    </CSSTransition>

                </div>

            </Spin>
        </CommonDivWidget>
    );
};


export default InventoryGroupByName
